// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-subscribe-tsx": () => import("./../../../src/pages/subscribe.tsx" /* webpackChunkName: "component---src-pages-subscribe-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-author-template-author-template-tsx": () => import("./../../../src/templates/author-template/author-template.tsx" /* webpackChunkName: "component---src-templates-author-template-author-template-tsx" */),
  "component---src-templates-blog-list-blog-list-tsx": () => import("./../../../src/templates/BlogList/BlogList.tsx" /* webpackChunkName: "component---src-templates-blog-list-blog-list-tsx" */),
  "component---src-templates-category-template-category-template-tsx": () => import("./../../../src/templates/category-template/category-template.tsx" /* webpackChunkName: "component---src-templates-category-template-category-template-tsx" */),
  "component---src-templates-date-template-date-template-js": () => import("./../../../src/templates/date-template/date-template.js" /* webpackChunkName: "component---src-templates-date-template-date-template-js" */),
  "component---src-templates-search-template-search-template-js": () => import("./../../../src/templates/search-template/search-template.js" /* webpackChunkName: "component---src-templates-search-template-search-template-js" */),
  "component---src-templates-single-blog-single-blog-tsx": () => import("./../../../src/templates/single-blog/single-blog.tsx" /* webpackChunkName: "component---src-templates-single-blog-single-blog-tsx" */),
  "component---src-templates-tag-template-tag-template-js": () => import("./../../../src/templates/tag-template/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-tag-template-js" */)
}

